import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { OfficeItem } from "../types/OfficeItem";

interface OfficeListPageState {
  offices: OfficeItem[];
}

const initialState: OfficeListPageState = {
  offices: [],
};

export const officeListPageSlice = createSlice({
  name: "officeList",
  initialState,
  reducers: {
    setOffices: (state, action: PayloadAction<OfficeItem[]>) => {
      state.offices = action.payload;
    },
    clearOfficeListPage: (state) => {
      state.offices = [];
    },
  },
});

export const { setOffices, clearOfficeListPage } = officeListPageSlice.actions;
export const officeListPage = (state: RootState) => state.officeListPageSlice;
export default officeListPageSlice.reducer;
