import React from "react";
import { Box, Typography } from "@mui/material";
import { SentimentDissatisfied } from "@mui/icons-material";

interface EmptyStateProps {
  message: string;
  iconFontSize?: number;
}

const EmptyState: React.FC<EmptyStateProps> = ({ message, iconFontSize }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" padding={4}>
      <SentimentDissatisfied style={{ fontSize: iconFontSize ?? 80 }} />
      <Typography variant="subtitle1" gutterBottom>
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyState;
