import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Backdrop,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { updateSettings, getSettings } from "./SettingsRepository";
import { CompanySettings } from "../../types/CompanySettings";
import {
  setPrimaryColor as setReduxPrimaryColor,
  setSecondaryColor as setReduxSecondaryColor,
  setTertiaryColor as setReduxTertiaryColor,
  setLogo as setReduxLogo,
  setLoginImage as setReduxLoginImage,
} from "../../reducers/themeSlice";

const SettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const companyId = useAppSelector((state) => state.user.user?.companyId);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<File | null>(null);
  const [loginImage, setLoginImage] = useState<File | null>(null);
  const [primaryColor, setPrimaryColor] = useState<string>(theme.palette.primary.main);
  const [secondaryColor, setSecondaryColor] = useState<string>(theme.palette.secondary.main);
  const [tertiaryColor, setTertiaryColor] = useState<string>("#FFFFFF");

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (companyId) {
          const settings = await getSettings(companyId);
          if (settings) {
            setPrimaryColor(settings.primary_color || theme.palette.primary.main);
            setSecondaryColor(settings.secondary_color || theme.palette.secondary.main);
            setTertiaryColor(settings.tertiary_color || "#FFFFFF");
          }
        }
      } catch (error) {
        dispatch(showSnackbar({ message: "Failed to load settings", severity: "error" }));
      }
    };

    fetchSettings();
  }, [dispatch, companyId, theme.palette.primary.main, theme.palette.secondary.main]);

  const handleFileChange = (setFile: React.Dispatch<React.SetStateAction<File | null>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleInputChange = (setState: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  const readFileAsDataURL = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const updateCompanySettings = async (data: CompanySettings) => {
    try {
      await updateSettings(data);
      dispatch(showSnackbar({ message: "Settings updated successfully", severity: "success" }));
      if (data.primary_color) {
        dispatch(setReduxPrimaryColor(data.primary_color));
      }
      if (data.secondary_color) {
        dispatch(setReduxSecondaryColor(data.secondary_color));
      }
      if (data.tertiary_color) {
        dispatch(setReduxTertiaryColor(data.tertiary_color));
      }
      dispatch(setReduxLogo(data.logo ?? null));
      dispatch(setReduxLoginImage(data.login_image ?? null));
    } catch (error) {
      dispatch(showSnackbar({ message: "Failed to update settings", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const data: CompanySettings = {
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      tertiary_color: tertiaryColor,
    };

    if (logo) {
      data.logo = await readFileAsDataURL(logo);
    }
    if (loginImage) {
      data.login_image = await readFileAsDataURL(loginImage);
    }

    updateCompanySettings(data);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `linear-gradient(20deg, ${primaryColor}, ${secondaryColor}, ${primaryColor})`,
      }}
    >
      <Backdrop open={isLoading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>
      <Card>
        <CardContent>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("settings.title")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <InputLabel htmlFor="logo-upload">{t("settings.logoLabel")}</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="file"
                  id="logo-upload"
                  name="logo-upload"
                  onChange={handleFileChange(setLogo)}
                />
                <InputLabel htmlFor="login-image-upload">{t("settings.loginImageLabel")}</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="file"
                  id="login-image-upload"
                  name="login-image-upload"
                  onChange={handleFileChange(setLoginImage)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="primaryColor"
                  label={t("settings.primaryColorLabel")}
                  name="primaryColor"
                  type="color"
                  value={primaryColor}
                  onChange={handleInputChange(setPrimaryColor)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="secondaryColor"
                  label={t("settings.secondaryColorLabel")}
                  name="secondaryColor"
                  type="color"
                  value={secondaryColor}
                  onChange={handleInputChange(setSecondaryColor)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="tertiaryColor"
                  label={t("settings.tertiaryColorLabel")}
                  name="tertiaryColor"
                  type="color"
                  value={tertiaryColor}
                  onChange={handleInputChange(setTertiaryColor)}
                />
                <Button type="button" fullWidth variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleSubmit}>
                  {t("settings.submit")}
                </Button>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SettingsPage;
