import React from "react";
import { red, green, yellow } from "@mui/material/colors";
import { Box, Typography, styled, Tooltip } from "@mui/material";
import { WorkExperience } from "../types/WorkExperience";
import { Education } from "../types/Education";

// Helper function to calculate the total duration and return boxes
const generateTimelineBoxes = (
  workExperiences: WorkExperience[],
  educations: Education[]
): { timeline: JSX.Element[]; labels: JSX.Element[] } => {
  const timeline: JSX.Element[] = [];
  const labels: JSX.Element[] = [];
  let currentTime = new Date(workExperiences[0]?.fromDate || educations[0]?.fromDate);

  // Helper to calculate the duration in days between two dates
  const calculateDuration = (start: string, end: string | null) => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : new Date();
    const duration = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    return duration;
  };

  const formatDuration = (days: number): string => {
    const years = Math.floor(days / 365);
    const remainingDaysAfterYears = days % 365;
    const months = Math.floor(remainingDaysAfterYears / 30);

    let formattedDuration = "";

    if (years > 0) {
      formattedDuration += `${years} year${years > 1 ? "s" : ""}`;
    }

    if (months > 0) {
      if (formattedDuration) {
        formattedDuration += ", "; // Add a comma only if there's a preceding year
      }
      formattedDuration += `${months} month${months > 1 ? "s" : ""}`;
    }

    return formattedDuration.trim();
  };

  // Process work experiences with durations
  const workDuration = workExperiences.map((work) => ({
    ...work,
    duration: calculateDuration(work.fromDate, work.toDate),
    type: "work" as const,
  }));

  // Process educations with durations
  const educationDuration = educations.map((education) => ({
    ...education,
    duration: calculateDuration(education.fromDate, education.toDate),
    type: "education" as const,
  }));

  // Combine and sort experiences by start date
  const combinedExperiences = [...workDuration, ...educationDuration].sort(
    (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime()
  );

  // Generate boxes based on the combined experiences
  combinedExperiences.forEach((experience) => {
    const { duration, type, fromDate } = experience;
    const startDate = new Date(fromDate);

    // Add gap for unregistered time (if any)
    const timeGap = calculateDuration(currentTime.toISOString(), startDate.toISOString());
    if (timeGap > 30) {
      timeline.push(
        <Tooltip
          key={`gap-${currentTime}`}
          title={
            <React.Fragment>
              <Typography variant="body2" color="inherit">
                Unemployment
              </Typography>
              <Typography variant="body2" color="inherit">
                Type: Unemployment
              </Typography>
              <Typography variant="body2" color="inherit">
                {formatDuration(timeGap)}
              </Typography>
            </React.Fragment>
          }
        >
          <Box
            bgcolor={red[500]}
            width={`${timeGap * 2}px`}
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={1}
            mt={"80px"}
            sx={{
              p: 2,
              borderRadius: 1,
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: red[700],
              },
            }}
          >
            {timeGap > 100 && (
              <Typography variant="caption" color="inherit">
                {formatDuration(timeGap)}
              </Typography>
            )}
          </Box>
        </Tooltip>
      );
    }

    // Add the work or education box with tooltip
    timeline.push(
      <Tooltip
        key={experience.id}
        title={
          <React.Fragment>
            <Typography variant="body2" color="inherit">
              {type === "work" ? experience.workplace : experience.provider}
            </Typography>
            <Typography variant="body2" color="inherit">
              {type === "work" ? experience.position : experience.subject}
            </Typography>
            <Typography variant="body2" color="inherit">
              Type: {type.charAt(0).toUpperCase() + type.slice(1)}
            </Typography>
            <Typography variant="body2" color="inherit">
              {experience.fromDate} - {experience.toDate || "Present"}
            </Typography>
          </React.Fragment>
        }
      >
        <Box
          bgcolor={type === "work" ? green[500] : yellow[500]}
          width={`${duration * 2}px`}
          height="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          m={1}
          mt={type === "work" ? "0" : "40px"}
          sx={{
            p: 2,
            borderRadius: 1,
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: type === "work" ? green[700] : yellow[700],
            },
          }}
        >
          {duration > 100 && (
            <Typography variant="caption" color="black">
              {formatDuration(duration)}
            </Typography>
          )}
        </Box>
      </Tooltip>
    );

    // Add labels for the timeline
    labels.push(
      <Box key={`label-${startDate.getFullYear()}-${startDate.getMonth()}`} m={1}>
        <Typography variant="caption" color="inherit">
          {startDate.getFullYear()}-{startDate.toLocaleString("default", { month: "short" })}
        </Typography>
      </Box>
    );

    currentTime = new Date(experience.toDate || new Date());
  });
  return { timeline, labels };
};

interface MaijaExperienceTimelineProps {
  workExperiences: WorkExperience[];
  educations: Education[];
}

const MaijaExperienceTimeline: React.FC<MaijaExperienceTimelineProps> = ({ workExperiences, educations }) => {
  const { timeline, labels } = generateTimelineBoxes(workExperiences, educations);

  // Calculate the start year (first experience year) and end year (current year)
  const getEarliestYear = () => {
    const workYears = workExperiences.map((exp) => new Date(exp.fromDate).getFullYear());
    const educationYears = educations.map((edu) => new Date(edu.fromDate).getFullYear());

    // Combine all years into one array
    const allYears = [...workYears, ...educationYears];

    // Get the earliest year
    return Math.min(...allYears);
  };

  const startYear = getEarliestYear();
  const endYear = new Date().getFullYear();

  const calculateMilestones = (startYear: number, endYear: number, numberOfMilestones: number = 4): number[] => {
    const totalYears = endYear - startYear;

    // If the total number of years is less than or equal to the number of milestones
    if (totalYears <= numberOfMilestones) {
      return Array.from({ length: totalYears }, (_, index) => startYear + index + 1);
    }

    // Calculate the interval as a floating-point number
    const milestoneInterval = totalYears / (numberOfMilestones + 1);

    // Use Math.round to ensure milestones are spread evenly and close to intended positions
    return Array.from({ length: numberOfMilestones }, (_, index) => Math.round(startYear + (index + 1) * milestoneInterval));
  };

  // Determine milestone years
  const milestones = calculateMilestones(startYear, endYear);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" padding={4}>
      <Box display="flex" justifyContent="center" width="100%" mb={5} sx={{ height: 120 }}>
        {timeline}
      </Box>

      {/* Include the Timeline Component at the Bottom */}
      <TimelineAxis startYear={startYear} endYear={endYear} milestones={milestones} />
    </Box>
  );
};

interface TimelineProps {
  startYear: number;
  endYear: number;
  milestones: number[];
}

const TimelineAxis: React.FC<TimelineProps> = ({ startYear, endYear, milestones }) => {
  const totalYears = endYear - startYear;
  const calculateLeftPosition = (year: number) => {
    return ((year - startYear) / totalYears) * 100;
  };

  return (
    <Box sx={{ position: "relative", width: "100%", mt: 2, height: 30 }}>
      {/* Main timeline line */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 3,
          backgroundColor: "black",
          position: "relative",
          top: 15, // Adjusting to move the line lower within the component
        }}
      >
        {/* Arrow at the end */}
        <Box
          sx={{
            position: "absolute",
            right: "-4px",
            top: "-5px", // Moving the arrow head down to align with the timeline line
            borderLeft: "15px solid black",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
          }}
        />
      </Box>

      {/* Start year */}
      <Box sx={{ position: "absolute", left: "0%", top: "-20px" }}>
        <Typography
          sx={{
            mt: 0,
            transform: "translateX(-50%)",
          }}
        >
          {startYear}
        </Typography>
        <Box sx={{ width: 2, height: 15, backgroundColor: "black", mt: 2, position: "relative", top: -10 }} />
      </Box>

      {/* Milestones */}
      {milestones.map((year) => (
        <Box key={year} sx={{ position: "absolute", left: `${calculateLeftPosition(year)}%`, top: "-10px" }}>
          <Typography
            sx={{
              position: "relative",
              mt: 0,
              top: -10,
              transform: "translateX(-50%)",
            }}
          >
            {year}
          </Typography>
          <Box sx={{ width: 2, height: 15, backgroundColor: "black", mt: 1, position: "relative", top: -15 }} />
        </Box>
      ))}

      {/* End year */}
      <Box sx={{ position: "absolute", left: "100%", top: "-20px", transform: "translateX(-50%)" }}>
        <Typography
          sx={{
            mt: 0,
            transform: "translateX(-50%)",
          }}
        >
          {endYear}
        </Typography>
        <Box sx={{ width: 2, height: 15, backgroundColor: "black", mt: 2, position: "relative", top: -10 }} />
      </Box>
    </Box>
  );
};

export default MaijaExperienceTimeline;
