export enum EvaluationCriteria {
  A = "A",
  B = "B",
  C = "C",
}

export const parseEvaluationCriteria = (input: string): EvaluationCriteria | null => {
  const trimmedInput = input.trim(); // Remove any leading/trailing whitespace
  if (Object.values(EvaluationCriteria).includes(trimmedInput as EvaluationCriteria)) {
    return trimmedInput as EvaluationCriteria;
  }
  return null;
};
