import React, { FormEvent, useState } from "react";
import { TextField, Button, Container, useTheme, Paper, Typography, CircularProgress, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch } from "../../hooks";
import { setLoggedInUser } from "../../reducers/userSlice";
import { setPrimaryColor, setSecondaryColor, setLogo, setLoginImage, setTertiaryColor } from "../../reducers/themeSlice";
import { login, getSettings } from "./LoginRepository";
import { useNavigate } from "react-router-dom";
import { APPLICANT_LIST_ROUTE, FORGOT_PASSWORD_ROUTE, OFFICE_LIST_ROUTE } from "../../Routes";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { SecurityLevel } from "../../types/User";
import { useTranslation } from "react-i18next";

interface LoginFormData {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState<LoginFormData>({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const loggedInUser = await login({ email: formData.email, password: formData.password });
      dispatch(setLoggedInUser(loggedInUser));

      // Fetch company settings
      const settings = await getSettings(loggedInUser.companyId);
      if (settings.primary_color) {
        dispatch(setPrimaryColor(settings.primary_color));
      }
      if (settings.secondary_color) {
        dispatch(setSecondaryColor(settings.secondary_color));
      }
      if (settings.tertiary_color) {
        dispatch(setTertiaryColor(settings.tertiary_color));
      }
      dispatch(setLogo(settings.logo ?? null));
      dispatch(setLoginImage(settings.login_image ?? null));

      if (loggedInUser.securityLevel >= SecurityLevel.CompanyAdmin) {
        navigate(OFFICE_LIST_ROUTE.path);
      } else {
        navigate(APPLICANT_LIST_ROUTE.path);
      }
    } catch (errorMessage) {
      console.log(errorMessage);
      dispatch(
        showSnackbar({
          message: t("login_page.failed"),
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xl" disableGutters>
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} height="100vh">
        {!isSmallScreen && (
          <Box
            flex="0 0 70%"
            style={{
              backgroundImage: `linear-gradient(20deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h2" sx={{ color: (theme) => theme.palette.primary.contrastText }}>
              {t("login_page.welcome_admin")}
            </Typography>
          </Box>
        )}
        <Box
          flex={isSmallScreen ? "1 0 auto" : "0 0 30%"}
          component={Paper}
          elevation={6}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          p={4}
          square
          height={isSmallScreen ? "auto" : "100%"}
        >
          <Box alignItems="flex-start" justifyContent="center" display="flex" flexDirection="column">
            <Typography variant="h3">{t("login_page.title")}</Typography>
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={t("login_page.email_label")}
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={t("login_page.password_label")}
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading} sx={{ mt: 3 }}>
                {t("login_page.button")}
                {isLoading && <CircularProgress size={22} sx={{ ml: 3 }} />}
              </Button>
              <Button
                variant="text"
                sx={{ mt: 3 }}
                onClick={() => {
                  navigate(FORGOT_PASSWORD_ROUTE.path);
                }}
              >
                {t("login_page.forgot_password_button")}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
