import React from "react";
import { Card, CardContent, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
interface MaijaInfoCardProps {
  icon: React.ReactElement;
  title: string;
  value: number;
  navigateToAdd?: () => void; // Make navigateToAdd optional
}

const MaijaInfoCard: React.FC<MaijaInfoCardProps> = ({ icon, title, value, navigateToAdd }) => {
  return (
    <Card
      sx={{
        m: 2,
        flex: "1 1 200px",
        height: 300,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {navigateToAdd && (
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={navigateToAdd}
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
      )}
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexGrow: 1,
        }}
      >
        {React.cloneElement(icon, { sx: { fontSize: 80, marginBottom: 1 } })}
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4">{value}</Typography>
      </CardContent>
    </Card>
  );
};

export default MaijaInfoCard;
