import React from "react";
import Container from "@mui/material/Container";
import { ContainerProps } from "@mui/material";

interface MaijaContainerProps extends ContainerProps {}

const MaijaContainer: React.FC<MaijaContainerProps> = ({ children, ...otherProps }) => {
  return (
    <Container
      sx={{
        padding: 5,
      }}
      {...otherProps}
    >
      {children}
    </Container>
  );
};

export default MaijaContainer;
