import { CircularProgress, Box } from '@mui/material';

interface MaijaLoadingPageProps {
    isFullscreen?: boolean;
}


const MaijaLoadingPage: React.FC<MaijaLoadingPageProps> = (isFullscreen) => {
    if (isFullscreen) return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ m: 5 }}
            width={"100%"}
            height={"100%"}
        >
            <CircularProgress size={100} />
        </Box>
    )

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ m: 5 }}
        >
            <CircularProgress size={100} />
        </Box>
    );
};


export default MaijaLoadingPage;
