import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { JobCoachItem } from "../types/JobCoachItem";

interface JobCoachListPageState {
  jobCoaches: JobCoachItem[];
}

const initialState: JobCoachListPageState = {
  jobCoaches: [],
};

export const jobCoachListPageSlice = createSlice({
  name: "jobCoachList",
  initialState,
  reducers: {
    setJobCoaches: (state, action: PayloadAction<JobCoachItem[]>) => {
      state.jobCoaches = action.payload;
    },
    clearJobCoachListPage: (state) => {
      state.jobCoaches = [];
    },
  },
});

export const { setJobCoaches, clearJobCoachListPage } = jobCoachListPageSlice.actions;
export const jobCoachListPage = (state: RootState) => state.jobCoachListPageSlice;
export default jobCoachListPageSlice.reducer;
