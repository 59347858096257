import { Box, Skeleton, Paper, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaijaModal from "../../components/MaijaModal";
import { getApplicantReport } from "./ApplicantsDetailRepository";
import { ApplicantReport } from "../../types/ApplicantReport";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useAppDispatch } from "../../hooks";

interface ResumePreviewModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const calculateWidthFromHeight = (height: number) => {
  return height / 1.414; // A4 aspect ratio (21cm/29.7cm)
};

const A4Paper = styled(Paper)(({ theme }) => ({
  height: "120vh",
  width: `${calculateWidthFromHeight(window.innerHeight * 1.2)}px`,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  borderRadius: "8px",
}));

/**
 * Copied from ResumePreviewModal.tsx in maija-applicant. Should be added as a shared component.
 */

const ReportPreviewModal: React.FC<ResumePreviewModalProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [applicantReport, setApplicantReport] = useState<ApplicantReport | undefined>(undefined);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const fetchApplicantReport = async () => {
        try {
          const newReport = await getApplicantReport("applicantDetails.applicantId");
          setApplicantReport(newReport);
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("applicantDetails.reportModal.errorLoading"), // TODO: Add translation
              severity: "error",
            })
          );
          handleClose();
        } finally {
          setIsLoading(false);
        }
      };

      fetchApplicantReport();
    }
  }, [isOpen]);

  const reportThumbnails = [applicantReport?.applicantReportThumbnailEncoded];

  return (
    <MaijaModal
      isOpen={isOpen}
      handleClose={handleClose}
      width={calculateWidthFromHeight(window.innerHeight * 0.8).toString()}
      maxHeight="95vh"
    >
      {isLoading ? (
        <Skeleton variant="rounded" animation="wave" width={calculateWidthFromHeight(window.innerHeight * 1.2)} height="120vh" />
      ) : (
        reportThumbnails.map((thumbnail, index) => {
          const isNotLastItem = index !== reportThumbnails.length - 1;
          return (
            <Box key={`box-${index}`} sx={{ maxHeight: "120vh" }}>
              <A4Paper key={index} elevation={10} sx={{ mb: isNotLastItem ? 2 : 0 }}>
                <img
                  src={`data:image/jpeg;base64,${thumbnail}`}
                  alt={`${t("Kan inte visa rapport sida: ")} ${index + 1}`}
                  style={{ width: "100%", height: "100%", borderRadius: "8px" }}
                />
              </A4Paper>
            </Box>
          );
        })
      )}
    </MaijaModal>
  );
};

export default ReportPreviewModal;

/*
  const reportThumbnails = [
    "https://maija.blob.core.windows.net/company/Rapport_1.png",
    "https://maija.blob.core.windows.net/company/Rapport_2_ny.png",
    "https://maija.blob.core.windows.net/company/Rapport_3_ny.png",
  ];

  return (
    <MaijaModal
      isOpen={isOpen}
      handleClose={handleClose}
      width={calculateWidthFromHeight(window.innerHeight * 0.8).toString()}
      maxHeight="95vh"
    >
      {isLoading ? (
        <Skeleton variant="rounded" animation="wave" width={calculateWidthFromHeight(window.innerHeight * 1.2)} height="120vh" />
      ) : (
        reportThumbnails.map((thumbnailUrl, index) => {
          const isNotLastItem = index !== reportThumbnails.length - 1;
          return (
            <Box key={`box-${index}`} sx={{ maxHeight: "120vh" }}>
              <A4Paper key={index} elevation={10} sx={{ mb: isNotLastItem ? 2 : 0 }}>
                <img
                  src={`${thumbnailUrl}`}
                  alt={`${t("Kan inte visa rapport sida: ")} ${index + 1}`}
                  style={{ width: "100%", height: "100%", borderRadius: "8px" }}
                />
              </A4Paper>
            </Box>
          );
        })
      )}
    </MaijaModal>
  );
  */
