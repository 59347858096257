import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../hooks";
import MaijaRoute, {
  APPLICANT_LIST_ROUTE,
  CREATE_APPLICANT_ROUTE,
  PLANNING_ROUTE,
  LOGIN_ROUTE,
  OFFICE_LIST_ROUTE,
  JOB_COACH_LIST_ROUTE,
  COMPANY_CREATE_ROUTE,
  ViewMode,
  JOB_COACH_DETAILS_ROUTE,
  ANALYTICS_ROUTE,
} from "../Routes";
import MaijaAppBar from "./MaijaAppBar";
import { showSnackbar } from "./../reducers/snackbarSlice";
import { SecurityLevel } from "../types/User";
import { useTranslation } from "react-i18next";

type ProtectedRouteProps = {
  route: MaijaRoute;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route, children }) => {
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  if (!user) {
    return <Navigate to={LOGIN_ROUTE.path} replace state={{ from: location }} />;
  }
  if (!route.securityLevel || user.securityLevel < route.securityLevel) {
    dispatch(
      showSnackbar({
        message: t("generic.error"),
        severity: "error",
      })
    );
    return <Navigate to={LOGIN_ROUTE.path} replace state={{ from: location }} />;
  }

  let toolbarRoutes = [];

  if (user.securityLevel >= SecurityLevel.CompanyAdmin) {
    toolbarRoutes = [OFFICE_LIST_ROUTE, JOB_COACH_LIST_ROUTE, APPLICANT_LIST_ROUTE, COMPANY_CREATE_ROUTE, ANALYTICS_ROUTE, PLANNING_ROUTE];
  } else {
    toolbarRoutes = [JOB_COACH_DETAILS_ROUTE, APPLICANT_LIST_ROUTE, CREATE_APPLICANT_ROUTE, PLANNING_ROUTE];
  }

  const containerStyle = {
    height: "100vh",
    minWidth: route.viewMode === ViewMode.Wide ? "1000px" : "100%",
    margin: "0 auto", // Center the container when maxWidth is applied
  };

  return (
    <div style={containerStyle}>
      <MaijaAppBar routes={toolbarRoutes} />
      <div style={{ height: "100vh" }}>{children}</div>
    </div>
  );
};

export default ProtectedRoute;
