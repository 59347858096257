import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from "axios";
import store from "../store";
import { API_BASE_URL } from "../config";
import { logout } from "./LogoutUtil";

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);

  const state = store.getState();
  const token = state.user.user?.accessToken;

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === 401) {
    logout();
  }

  console.error(`[response error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

// Keep jusr in case
// const transformDates = (obj: any): void => {
//   for (const key in obj) {
//     if (
//       typeof obj[key] === "string" &&
//       obj[key].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
//     ) {
//       obj[key] = dayjs(obj[key]);
//     } else if (typeof obj[key] === "object") {
//       transformDates(obj[key]);
//     }
//   }
// };

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

export default axiosInstance;
