export type User = {
  userId: string;
  companyId: string;
  jobCoachId: string;
  accessToken: string;
  name: string;
  fullName: string;
  securityLevel: SecurityLevel;
  profileIconUrl: string | null;
};

export enum SecurityLevel {
  None = 0,
  JobCoach = 100,
  CompanyAdmin = 200,
}
