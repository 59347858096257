import { useEffect, useState } from "react";
import { fetchJobCoachItems } from "./JobCoachListRepository";
import { Box, Card, List, Divider, ListItem, Grid, Paper, ListItemButton, Typography, Button, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { navigateToJobCoachDetails } from "../../Routes";
import { COMPANY_CREATE_ROUTE } from "../../Routes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setJobCoaches } from "../../reducers/jobCoachListPageSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import ClickableHeaderItem from "../../components/ClickableHeaderItem";
import MaijaContainer from "../../components/MaijaContainer";
import { setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { JobCoachItem } from "../../types/JobCoachItem";
import { useTranslation } from "react-i18next";
import { SupervisedUserCircle } from "@mui/icons-material";

const JobCoachesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const jobCoaches = useAppSelector((state) => state.jobCoachListPageSlice.jobCoaches);

  useEffect(() => {
    setIsLoading(true);
    fetchJobCoachItems()
      .then((data) => dispatch(setJobCoaches(data)))
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading && jobCoaches.length === 0) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (jobCoaches.length === 0) {
    return (
      <MaijaContainer>
        <JobCoachesListEmptyState />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => {
          dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.JobCoachTab));
          navigate(COMPANY_CREATE_ROUTE.path);
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("jobCoachesList.addJobCoach")}</Typography>
      </Fab>
      <Typography variant="h4" gutterBottom sx={{ ml: 5 }}>
        {t("jobCoachesList.yourJobCoaches")}
      </Typography>
      <Box sx={{ m: 5, mb: 30 }}>
        <JobCoachList jobCoaches={jobCoaches} />
      </Box>
    </MaijaContainer>
  );
};

type JobCoachListProps = {
  jobCoaches: JobCoachItem[];
};

export const JobCoachList: React.FC<JobCoachListProps> = ({ jobCoaches }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <List component={Paper}>
      <ListItem key={"header"}>
        <Grid container>
          <Grid item xs={3}>
            <ClickableHeaderItem
              text={t("jobCoachesList.name")}
              onClick={() => {
                const sortedCoaches = jobCoaches.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
                dispatch(setJobCoaches(sortedCoaches));
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <ClickableHeaderItem
              text={t("jobCoachesList.numberOfApplicants")}
              onClick={() => {
                const sortedCoaches = jobCoaches.slice().sort((a, b) => a.numberApplicants - b.numberApplicants);
                dispatch(setJobCoaches(sortedCoaches));
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <ClickableHeaderItem
              text={t("jobCoachesList.appliedJobsLast30Days")}
              onClick={() => {
                const sortedCoaches = jobCoaches.slice().sort((a, b) => a.numberJobsLast30Days - b.numberJobsLast30Days);
                dispatch(setJobCoaches(sortedCoaches));
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={3}></Grid>
      </ListItem>

      <Divider />

      {jobCoaches.map((jobCoach) => {
        return (
          <ListItemButton
            key={jobCoach.id}
            onClick={() => {
              navigateToJobCoachDetails(navigate, jobCoach.id);
            }}
          >
            <Grid container>
              <Grid item xs={3}>
                {jobCoach.fullName}
              </Grid>
              <Grid item xs={3}>
                {jobCoach.numberApplicants}
              </Grid>
              <Grid item xs={3}>
                {jobCoach.numberJobsLast30Days}
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </ListItemButton>
        );
      })}
    </List>
  );
};

export const JobCoachesListEmptyState = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateJobCoachButton = () => {
    dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.JobCoachTab));
    navigate(COMPANY_CREATE_ROUTE.path);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SupervisedUserCircle style={{ fontSize: 150 }} sx={{ mb: 5 }} />
          <Typography variant="h4" gutterBottom>
            {t("jobCoachesList.noJobCoaches")}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCreateJobCoachButton} sx={{ m: 5 }}>
            {t("jobCoachesList.createJobCoach")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default JobCoachesListPage;
