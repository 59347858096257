import { Grid, Typography } from "@mui/material";

interface DisplayedValueProps {
    name: String,
    value: string | undefined | null
}

const DisplayedValue: React.FC<DisplayedValueProps> = ({ name, value }) => {
    if (!value || value === "") {
        return null;
    }
    return (
        <Grid container columnGap={1}>
            <Grid item>
                <Typography variant="body1" color="textSecondary">{name}:</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">{value}</Typography>
            </Grid>
        </Grid>
    );
}

export default DisplayedValue;