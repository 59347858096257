import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import { Box, Typography, Card, CardContent, Grid, CircularProgress, Backdrop, Divider, List, ListItem, ListItemText } from "@mui/material";
import MaijaLottieAnimation from "../../components/MaijaLottieAnimation";
import successAnimation from "../../assets/lottie/success.json";
import { useAppDispatch } from "../../hooks";
import LastDayEdit from "./LastDayEdit";
import { useTranslation } from "react-i18next";

interface ApplicantSupportInfo {
  id: string;
  companyId: string | null;
  applicantId: string;
  hasWorkLimitations: boolean;
  canTakeWorkImmediately: boolean;
  canWorkDaytime: boolean;
  canWorkEvening: boolean;
  canWorkNighttime: boolean;
  fullTime: boolean;
  partTimePercentage: number | null;
  unemploymentOrSickLeaveDate: string | null;
  sfiLevel: string;
  educationLevel: string;
  firstPreferredJob: string | null;
  secondPreferredJob: string | null;
  thirdPreferredJob: string | null;
  desiredJobInFiveYears: string | null;
  lastDay: string | null;
}

interface ApplicantSupportProps {
  applicantId: string;
}

const formatDate = (dateString: string | null): string => {
  if (!dateString) return "";
  return dateString.split("T")[0];
};

const ApplicantSupportInfo: React.FC<ApplicantSupportProps> = ({ applicantId }) => {
  const [supportInfo, setSupportInfo] = useState<ApplicantSupportInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSupportInfo = async () => {
      try {
        const response = await axiosInstance.get(`/applicant_support_info/${applicantId}`);
        setSupportInfo(response.data);
      } catch (error) {
        console.error("Error fetching applicant support info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSupportInfo();
  }, [applicantId]);

  const handleSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  if (loading && !success) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!supportInfo) {
    return <Typography>{t('applicantDetails.noSupportInformationFound.')}</Typography>;
  }

  const renderSupportInfoItem = (label: string, value: any, index: number) => (
    <ListItem key={label} sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff' }}>
      <ListItemText primary={label} secondary={value} />
    </ListItem>
  );

  return (
    <Box sx={{ mt: 4, p: 2 }}>
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>
      <MaijaLottieAnimation animationData={successAnimation} size={300} show={success} />

      <LastDayEdit applicantId={applicantId} initialLastDay={supportInfo.lastDay} onSuccess={handleSuccess} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('applicantDetails.workAvailability')}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                {renderSupportInfoItem(t('applicantDetails.hasWorkLimitations'), supportInfo.hasWorkLimitations ? t('applicantDetails.yes') : t('applicantDetails.no'), 0)}
                {renderSupportInfoItem(t('applicantDetails.canTakeWorkImmediately'), supportInfo.canTakeWorkImmediately ? t('applicantDetails.yes') : t('applicantDetails.no'), 1)}
                {renderSupportInfoItem(t('applicantDetails.canWorkDaytime'), supportInfo.canWorkDaytime ? t('applicantDetails.yes') : t('applicantDetails.no'), 2)}
                {renderSupportInfoItem(t('applicantDetails.canWorkEvening'), supportInfo.canWorkEvening ? t('applicantDetails.yes') : t('applicantDetails.no'), 3)}
                {renderSupportInfoItem(t('applicantDetails.canWorkNighttime'), supportInfo.canWorkNighttime ? t('applicantDetails.yes') : t('applicantDetails.no'), 4)}
                {renderSupportInfoItem(t('applicantDetails.fullTime'), supportInfo.fullTime ? t('applicantDetails.yes') : t('applicantDetails.no'), 5)}
                {renderSupportInfoItem(
                  t('applicantDetails.partTimePercentage'),
                  supportInfo.partTimePercentage !== null ? `${supportInfo.partTimePercentage}%` : t('applicantDetails.n/a'),
                  6
                )}
                {renderSupportInfoItem(t('applicantDetails.unemploymentOrSickLeaveDate'), supportInfo.unemploymentOrSickLeaveDate || t('applicantDetails.n/a'), 7)}
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('applicantDetails.education&Preferences')}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                {renderSupportInfoItem(t('applicantDetails.sfiLevel'), t(`sfiLevelsEnum.${supportInfo.sfiLevel}`), 0)}
                {renderSupportInfoItem(t('applicantDetails.educationLevel'), t(`educationLevelsEnum.${supportInfo.educationLevel}`), 1)}
                {renderSupportInfoItem(t('applicantDetails.firstPreferredJob'), supportInfo.firstPreferredJob || t('applicantDetails.n/a'), 2)}
                {renderSupportInfoItem(t('applicantDetails.secondPreferredJob'), supportInfo.secondPreferredJob || t('applicantDetails.n/a'), 3)}
                {renderSupportInfoItem(t('applicantDetails.thirdPreferredJob'), supportInfo.thirdPreferredJob || t('applicantDetails.n/a'), 4)}
                {renderSupportInfoItem(t('applicantDetails.desiredJobinFiveYears'), supportInfo.desiredJobInFiveYears || t('applicantDetails.n/a'), 5)}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplicantSupportInfo;
