import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import axiosInstance from "../../utils/AxiosInstance";

async function getApplicantAnalyticsItems(): Promise<ApplicantAnalyticsItem[]> {
  try {
    const response = await axiosInstance.get("/applicant_analytics_items", {
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export default getApplicantAnalyticsItems;
