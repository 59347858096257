import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import { Box, Typography, TextField, Button, Card, CardContent, Grid, Paper, Alert } from "@mui/material";
import { format } from "date-fns";
import { RootState } from "../../store";
import { useAppSelector } from "../../hooks";
import { motion } from "framer-motion";

interface Note {
  id: string;
  title: string;
  main_text: string;
  created_date: string;
  job_coach_id: string;
  job_coach_name: string;
}

interface NotesComponentProps {
  applicantId: string;
}

const NotesComponent: React.FC<NotesComponentProps> = ({ applicantId }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [title, setTitle] = useState("");
  const [mainText, setMainText] = useState("");
  const [error, setError] = useState("");
  const [showAddNote, setShowAddNote] = useState(false);

  const jobCoachId = useAppSelector((state: RootState) => state.user.user?.jobCoachId);

  useEffect(() => {
    axiosInstance
      .get(`/notes/${applicantId}`)
      .then((response) => setNotes(response.data))
      .catch((error) => console.error("Error fetching notes:", error));
  }, [applicantId]);

  const handleAddNote = () => {
    if (!title || !mainText) {
      setError("Title and main text are required");
      return;
    }

    axiosInstance
      .post("/note/create", {
        applicant_id: applicantId,
        job_coach_id: jobCoachId,
        title: title,
        main_text: mainText,
      })
      .then((response) => {
        setNotes([...notes, response.data]);
        setTitle("");
        setMainText("");
        setShowAddNote(false);
        setError("");
      })
      .catch((error) => {
        console.error("Error creating note:", error);
        setError("Failed to create note");
      });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={() => setShowAddNote(true)}>
        Add Note
      </Button>
      {showAddNote && (
        <Paper elevation={3} sx={{ p: 2, mb: 4, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth variant="outlined" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Main Text"
                value={mainText}
                onChange={(e) => setMainText(e.target.value)}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary" onClick={handleAddNote}>
                Submit
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
          p: 2,
          "& > *": { my: 1 },
        }}
      >
        {notes.map((note) => (
          <motion.div key={note.id} initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
            <Card sx={{ minWidth: 300 }}>
              <CardContent>
                <Typography variant="h6">{note.title}</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {note.main_text}
                </Typography>
                <Box display="block">
                  {note.job_coach_name && (
                    <Typography variant="caption" color="textSecondary">
                      By: {note.job_coach_name}
                    </Typography>
                  )}
                </Box>
                <Box display="block">
                  <Typography variant="caption" color="textSecondary">
                    {format(new Date(note.created_date), "PPPp")}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};

export default NotesComponent;
