import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Button,
  Fab,
  useMediaQuery,
  Theme,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { green } from "@mui/material/colors";
import DisplayedValue from "../../components/DisplayedValue";
import {
  applicantGotJob,
  downloadApplication,
  getApplicantDetails,
  updateApplicantStatus,
  getApplicantReport,
  removeApplicant,
  sendEmail,
} from "./ApplicantsDetailRepository";
import { ApplicantDetails } from "../../types/ApplicantDetails";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import { formatDateToString } from "../../utils/DateUtils";
import { useParams } from "react-router-dom";
import MaijaModal from "../../components/MaijaModal";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import MaijaAsyncComponent from "../../components/MaijaAsyncComponent";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import { StatusReport } from "../../types/StatusReport";
import { useNavigate } from "react-router-dom";
import { APPLICANT_LIST_ROUTE } from "../../Routes";
import Lottie from "react-lottie";
import clebrateAnimation from "../../assets/lottie/celebrate4.json";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setApplicant } from "../../reducers/applicantDetilsPageSlice";
import { showSnackbar } from "../../reducers/snackbarSlice";
import MaijaErrorPage from "../../components/MaijaErrorPage";
import MaijaSendEmailModal from "../../components/MaijaSendEmailModal";
import { BookingModal, BookingMode, BookingType } from "../planning/BookModal";
import MaijaContainer from "../../components/MaijaContainer";
import { downloadBase64File } from "../../utils/FileDownloadUtil";
import { CloudDownload, Timeline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import GraphVisualizer from "./GraphVisualizer";
import ApplicantSupportInfo from "./ApplicantSupportInfo";
import NotesComponent from "./NotesComponent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NoteIcon from "@mui/icons-material/Note";
import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import { SuccessParamters } from "./SuccessParameters";
import { Experiences } from "./CVDetails";
import ChangeJobCoachComponent from "./ChangeJobCoachComponent";
import EditIcon from "@mui/icons-material/Edit";
import { SecurityLevel } from "../../types/User";
import MaijaExperienceTimeline from "../../components/MaijaExperienceTimeline";
import ReportPreviewModal from "./ReportPreviewModal";

const ApplicantDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const isCompanyAdmin = user?.securityLevel === SecurityLevel.CompanyAdmin;
  const applicant = useAppSelector((state) => (id ? state.applicantDetilsPageSlice.applicantsMap[id] : undefined));
  const [applicantReportModalOpen, setApplicantReportModalOpen] = useState(false);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [removeApplicantModalOpen, setRemoveApplicantModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gotJobAnimation, setGotJobAnimation] = useState(false);
  const [status, setStatus] = useState("Available");
  const [isEditingJobCoach, setIsEditingJobCoach] = useState(false);
  const toggleJobCoachEdit = () => {
    setIsEditingJobCoach((prev) => !prev);
  };
  const [jobCoachName, setJobCoachName] = useState(applicant?.jobCoachName || "");
  const handleJobCoachChange = (newJobCoachName: string) => {
    setJobCoachName(newJobCoachName);
    setIsEditingJobCoach(false);
  };

  const navigate = useNavigate();

  const handleRemoveApplicant = async (applicantId: string) => {
    try {
      await removeApplicant(applicantId);
      navigate(APPLICANT_LIST_ROUTE.path);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.applicantRemoved"),
        })
      );
    } catch (error) {
      console.error("Error removing applicant:", error);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.failedToRemoveApplicant"),
          severity: "error",
        })
      );
    }
  };

  const handleStatusChange = async (event: { target: { value: any } }) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    try {
      if (!applicant) {
        return;
      }
      updateApplicantStatus(applicant?.applicantId, newStatus);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.statusUpdated"),
        })
      );
    } catch (error) {
      console.error("Error updating status:", error);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.failedToUpdateStatus"),
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    getApplicantDetails(id)
      .then((data) => {
        if (data) {
          dispatch(setApplicant(data));
          setStatus(data.status);
        }
      })
      .finally(() => setIsLoading(false));
  }, [id, dispatch]);

  useEffect(() => {
    if (applicant) {
      setJobCoachName(applicant.jobCoachName); // Initialize with applicant's current job coach name
    }
  }, [applicant]);

  if (isLoading) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (!applicant) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaErrorPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => setApplicantReportModalOpen(true)}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("applicantDetails.generateReport")}</Typography>
      </Fab>

      {gotJobAnimation && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9998 }}
        >
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: clebrateAnimation,
            }}
            height="100vh"
            width="100vw"
            isStopped={!gotJobAnimation}
            isPaused={!gotJobAnimation}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => {
                  setGotJobAnimation(false);
                },
              },
            ]}
          />
        </Box>
      )}

      {/* <ApplicatnReportModal
        applicantDetails={applicant}
        isOpen={applicantReportModalOpen}
        handleClose={() => setApplicantReportModalOpen(false)}
      /> */}

      <ReportPreviewModal isOpen={applicantReportModalOpen} handleClose={() => setApplicantReportModalOpen(false)}></ReportPreviewModal>

      <MaijaSendEmailModal
        sendToName={applicant.contactInformation.firstName}
        isOpen={sendEmailModalOpen}
        onSendEmail={(subject, body) => sendEmail(applicant.applicantId, subject, body)}
        onSuccess={() =>
          dispatch(
            showSnackbar({
              message: t("applicantDetails.emailSent"),
            })
          )
        }
        handleClose={() => setSendEmailModalOpen(false)}
      />
      <ConfirmActionDialog
        open={removeApplicantModalOpen}
        title={t("applicantDetails.deleteApplicant", { name: applicant.contactInformation.fullName })}
        description={t("applicantDetails.removeApplicantConfirmation", { firstName: applicant.contactInformation.firstName })}
        onClose={() => setRemoveApplicantModalOpen(false)}
        onAction={() => handleRemoveApplicant(applicant.applicantId)}
      />
      <BookingModal
        booking={{ mode: BookingMode.Create, type: BookingType.Meeting }}
        applicantName={{
          applicantId: applicant.applicantId,
          firstName: applicant.contactInformation.firstName,
          lastName: applicant.contactInformation.lastName,
          fullName: applicant.contactInformation.fullName,
        }}
        isOpen={bookingModalOpen}
        handleClose={() => {
          setBookingModalOpen(false);
        }}
      />
      <Paper elevation={3} sx={{ m: 4, p: 4 }}>
        <ApplicantInfomation
          applicantDetails={applicant}
          status={status}
          handleStatusChange={handleStatusChange}
          onGotJob={() => {
            setGotJobAnimation(true);
            applicantGotJob(applicant.applicantId, true).then((_) => {
              getApplicantDetails(applicant.applicantId).then((data) => {
                if (data) {
                  dispatch(setApplicant(data));
                }
              });
            });
          }}
          onSendEmail={() => setSendEmailModalOpen(true)}
          onBookMeeting={() => setBookingModalOpen(true)}
          onRemove={() => setRemoveApplicantModalOpen(true)}
        />

        {isCompanyAdmin && (
          <Box sx={{ ml: 2, mt: 3 }}>
            <Box display="inline-flex" alignItems="center">
              <DisplayedValue name={t("applicantDetails.jobCoachName")} value={jobCoachName} />
              <IconButton onClick={toggleJobCoachEdit} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>

            {isEditingJobCoach && (
              <Box sx={{ width: "50%" }}>
                <ChangeJobCoachComponent applicantId={applicant.applicantId} onJobCoachChange={handleJobCoachChange} />
              </Box>
            )}
          </Box>
        )}

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CheckCircleIcon sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
            <Typography variant="h5">{t("applicantDetails.successParameters")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SuccessParamters applicantDetails={applicant} />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Timeline sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
            <Typography variant="h5">{t("applicantDetails.experienceTimeline")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MaijaExperienceTimeline workExperiences={applicant.workExperiences} educations={applicant.educations} />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <AssignmentIcon sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
            <Typography variant="h5">{t("applicantDetails.applications")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GraphVisualizer applicantId={applicant.applicantId} />
            <Box sx={{ height: 36 }} />
            <Applications applicantDetails={applicant} />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <NoteIcon sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
            <Typography variant="h5">{t("applicantDetails.notes")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NotesComponent applicantId={applicant.applicantId} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <InfoIcon sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
            <Typography variant="h5">{t("applicantDetails.applicantSupportInfo")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApplicantSupportInfo applicantId={applicant.applicantId} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <WorkIcon sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
            <Typography variant="h5">{t("applicantDetails.experiences")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Experiences applicantDetails={applicant} />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </MaijaContainer>
  );
};

interface ApplicantInfomationProps {
  applicantDetails: ApplicantDetails;
  status: string;
  handleStatusChange: (event: { target: { value: any } }) => void;
  onGotJob: () => void;
  onSendEmail: () => void;
  onBookMeeting: () => void;
  onRemove: () => void;
}

const ApplicantInfomation: React.FC<ApplicantInfomationProps> = ({
  applicantDetails,
  status,
  handleStatusChange,
  onGotJob,
  onSendEmail,
  onBookMeeting,
  onRemove,
}) => {
  let avatarSize = GetAvatarSize();
  const { t } = useTranslation();
  let fullAddress = null;
  if (
    applicantDetails.contactInformation.address &&
    applicantDetails.contactInformation.zipCode &&
    applicantDetails.contactInformation.city
  ) {
    fullAddress = `${applicantDetails.contactInformation.address},\n  ${applicantDetails.contactInformation.zipCode} ${applicantDetails.contactInformation.city}`;
  }
  return (
    <Grid container>
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <Avatar
          sx={{ width: 170, height: 170 }}
          alt={applicantDetails.contactInformation.fullName}
          src={applicantDetails.profileIconUrl || undefined}
          style={{ width: avatarSize, height: avatarSize }}
        />
      </Grid>

      <Grid
        item
        xs
        container
        direction="column"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography variant="h5"> </Typography> {/* Adding space between top and name */}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h5">{applicantDetails.contactInformation.fullName}</Typography>
          <Typography sx={{ ml: 10, color: "#808080" }}>{t("applicantDetails.available")}</Typography>
          <Select value={status} onChange={handleStatusChange} size="small" sx={{ ml: 4, minWidth: 30 }}>
            <MenuItem value="Available">
              <CheckCircleIcon sx={{ color: green[500], mr: 1 }} />
            </MenuItem>
            <MenuItem value="Unavailable">
              <CancelIcon sx={{ color: "red", mr: 1 }} />
            </MenuItem>
          </Select>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
          {applicantDetails.hasJob && (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
              <CheckCircleIcon style={{ fontSize: 30, color: green[500] }} />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("applicantDetails.foundJob")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box flexDirection="row" sx={{ mt: 3, mb: 3 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }}
            onClick={onGotJob}
            disabled={applicantDetails.hasJob}
            disableElevation
          >
            {t("applicantDetails.gotJob")}
          </Button>
          <Button variant="contained" color="secondary" sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }} onClick={onSendEmail}>
            {t("applicantDetails.sendEmail")}
          </Button>
          <Button variant="contained" color="secondary" sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }} onClick={onBookMeeting}>
            {t("applicantDetails.bookMeeting")}
          </Button>
          <Button variant="contained" color="error" sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }} onClick={onRemove}>
            {t("applicantDetails.remove")}
          </Button>
        </Box>
      </Grid>

      <Grid item container xs={3}>
        {/* TODO: Fix space-evenly */}
        <Box display="flex" flexDirection="column" alignItems="space-evenly" alignContent="space-evenly" justifyContent="center">
          <DisplayedValue name={t("applicantDetails.phoneNumber")} value={applicantDetails.contactInformation.phoneNumber} />
          <DisplayedValue name={t("applicantDetails.email")} value={applicantDetails.contactInformation.email} />
          <DisplayedValue name={t("applicantDetails.address")} value={fullAddress} />
        </Box>
      </Grid>
    </Grid>
  );
};

interface ApplicationsProps {
  applicantDetails: ApplicantDetails;
}

const Applications: React.FC<ApplicationsProps> = ({ applicantDetails }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [downloading, setDownloading] = React.useState<{ [key: string]: boolean }>({});

  const handleDownloadApplication = (applicationId: string) => {
    setDownloading((prev) => ({ ...prev, [applicationId]: true }));
    downloadApplication(applicationId)
      .then((data) => {
        if (data) {
          downloadBase64File(data.resume, `Resume_${data.application.workplace}`);
          downloadBase64File(data.coverLetter, `Cover_letter_${data.application.workplace}`);
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: "An error occurred while downloading, try again.",
            severity: "error",
          })
        );
      })
      .finally(() => setDownloading((prev) => ({ ...prev, [applicationId]: false })));
  };

  let emptyStateComponent = <MaijaEmptyState message={t("applicantDetails.noApplications")} />;
  let listComponent = (
    <List style={{ width: "100%" }}>
      <ListItem key={"header"}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="h6" fontWeight="bold">
              {t("applicantDetails.companyName")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" fontWeight="bold">
              {t("applicantDetails.applicationStatus")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight="bold">
              {t("applicantDetails.dateApplied")}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
        }}
      >
        {applicantDetails.appliedJobs.map((appliedJob, index) => (
          <ListItemButton
            key={appliedJob.id}
            onClick={() => handleDownloadApplication(appliedJob.id)}
            sx={{ backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff" }}
          >
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="body1">{appliedJob.companyName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{appliedJob.appliedDate ? "Applied" : "Not Applied"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{formatDateToString(appliedJob.appliedDate)}</Typography>
              </Grid>
              <Grid item xs />
              <Grid item xs={2}>
                <Typography variant="body1">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={downloading[appliedJob.id] ? <CircularProgress size={24} color="inherit" /> : <CloudDownload />}
                    disabled={downloading[appliedJob.id]}
                  >
                    {t("applicantDetails.download")}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        ))}
      </Box>
    </List>
  );

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection={"column"} width="100%" sx={{ my: 4 }}>
      <Box sx={{ height: 400, overflow: "auto", width: "100%" }}>
        {applicantDetails.appliedJobs.length === 0 ? emptyStateComponent : listComponent}
      </Box>
    </Box>
  );
};

interface ApplicantReportModalProps {
  applicantDetails: ApplicantDetails;
  isOpen: boolean;
  handleClose: () => void;
}

// const ApplicatnReportModal: React.FC<ApplicantReportModalProps> = ({ applicantDetails, isOpen, handleClose }) => {
//   const { t } = useTranslation();
//   return (
//     <MaijaModal isOpen={isOpen} handleClose={handleClose}>
//       <MaijaAsyncComponent fetchData={() => getApplicantReport(applicantDetails.applicantId)} minHeight={500}>
//         {(report: StatusReport) => (
//           <Box display="flex" flexDirection="column" width="100%">
//             <Typography variant="h5">Report for {report.name}</Typography>
//             <Typography sx={{ mt: 3 }} variant="h6">
//               {t("applicantDetails.summary")}
//             </Typography>
//             <Typography sx={{ mt: 2 }}>{report.summary}</Typography>
//             <Typography sx={{ mt: 3 }} variant="h6">
//               {t("applicantDetails.whatNeedsToBeImproved?")}
//             </Typography>
//             <Typography sx={{ mt: 2, mb: 3 }}>{report.improvementPlan}</Typography>
//           </Box>
//         )}
//       </MaijaAsyncComponent>
//     </MaijaModal>
//   );
// };

function GetAvatarSize() {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between("md", "lg"));
  let avatarSize;
  if (isXSmall) {
    avatarSize = 80;
  } else if (isSmall) {
    avatarSize = 100;
  } else {
    avatarSize = 120;
  }
  return avatarSize;
}

export default ApplicantDetailsPage;
