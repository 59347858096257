import { ApplicantDetails } from "../../types/ApplicantDetails";
import { Box, Grid, Typography } from "@mui/material";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { formatDateToYearMonth } from "../../utils/DateUtils";
import { WorkExperience } from "../../types/WorkExperience";
import { Education } from "../../types/Education";
import { useTranslation } from "react-i18next";

export interface ExperiencesProps {
  applicantDetails: ApplicantDetails;
}

const sortEvents = (workExperiences: WorkExperience[], educations: Education[]) => {
  const combinedEvents = [
    ...workExperiences.map(exp => ({
      type: 'work',
      id: exp.id,
      fromDate: new Date(exp.fromDate),
      toDate: exp.toDate ? new Date(exp.toDate) : null,
      title: exp.workplace,
      subtitle: exp.position,
      description: exp.description
    })),
    ...educations.map(edu => ({
      type: 'education',
      id: edu.id,
      fromDate: new Date(edu.fromDate),
      toDate: edu.toDate ? new Date(edu.toDate) : null,
      title: edu.provider,
      subtitle: edu.subject,
      description: edu.description
    }))
  ];

  const unemploymentPeriods = calculateUnemploymentPeriods(combinedEvents);
  combinedEvents.push(...unemploymentPeriods);

  combinedEvents.sort((a, b) => b.fromDate.getTime() - a.fromDate.getTime());
  
  return combinedEvents;
};

const calculateUnemploymentPeriods = (events: any[]) => {
  const unemploymentPeriods = [];
  for (let i = 0; i < events.length - 1; i++) {
    const currentEvent = events[i];
    const nextEvent = events[i + 1];
    if (nextEvent.toDate && currentEvent.fromDate > nextEvent.toDate) {
      const durationMonths = Math.floor(
        (currentEvent.fromDate.getTime() - nextEvent.toDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
      );
      unemploymentPeriods.push({
        type: 'unemployment',
        id: `unemployment-${i}`,
        fromDate: nextEvent.toDate,
        toDate: currentEvent.fromDate,
        title: 'Unemployment Period',
        subtitle: '',
        /*description: t('applicantDetails.unemploymentDuration') + ': ' + durationMonths + ' ' + t('applicantDetails.month(s)'),*/
        description: `Unemployment duration: ${durationMonths} month(s)`,
      });
    }
  }
  return unemploymentPeriods;
};

export const Experiences: React.FC<ExperiencesProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  const showEmptyState =
    applicantDetails.workExperiences.length === 0 &&
    applicantDetails.educations.length === 0 &&
    applicantDetails.skills.length === 0 &&
    applicantDetails.languages.length === 0;

  const sortedEvents = sortEvents(applicantDetails.workExperiences, applicantDetails.educations);

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection={"column"} width="100%" sx={{ my: 4 }}>
      {showEmptyState && <MaijaEmptyState message={t("applicantDetails.noExperiences")} />}

      <Timeline
        position="alternate"
        sx={{
          opacity: 1,
          transition: 'opacity 0.3s ease-in-out',
          '&:hover': {
            opacity: 1,
          },
          '& .MuiTimelineItem-root:hover': {
            opacity: 1,
          },
          '& .MuiTimelineItem-root': {
            opacity: 0.6,
            transition: 'opacity 0.3s ease-in-out',
          }
        }}
      >
        {sortedEvents.map((event) => (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent color="text.secondary">
              {`${formatDateToYearMonth(event.fromDate.toISOString())} - ${event.toDate ? formatDateToYearMonth(event.toDate.toISOString()) : t('current')}`}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={
                event.type === 'work' ? 'primary' :
                event.type === 'education' ? 'secondary' : 'error'
              } />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box
                sx={{
                  position: 'relative',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  '&:hover': {
                    borderColor: 'primary.main',
                  },
                  '&:hover .event-description': {
                    visibility: 'visible',
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="h6" component="span">
                  {event.title}
                </Typography>
                {event.subtitle && <Typography>{event.subtitle}</Typography>}
                {event.description && (
                  <Typography
                    className="event-description"
                    variant="body2"
                    sx={{
                      visibility: 'hidden',
                      opacity: 0,
                      transition: 'visibility 0s, opacity 0.3s linear',
                    }}
                  >
                    {event.description}
                  </Typography>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export interface SkillsProps {
  applicantDetails: ApplicantDetails;
}

export const Skills: React.FC<SkillsProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  return (
    <Box width="100%">
      <Typography sx={{ mt: 2, mb: 0, mx: 1 }} variant="h5" fontWeight="bold">
        {t('applicantDetails.skills')}
      </Typography>
      <Grid container direction={"row"} sx={{ m: 2 }}>
        {applicantDetails.skills.map((skill, i) => {
          let divider = i < applicantDetails.skills.length - 1 ? ", " : "";
          return (
            <Typography key={i} sx={{ mr: 1 }} variant="body1">
              {skill.name}
              {divider}
            </Typography>
          );
        })}
      </Grid>
    </Box>
  );
};

export interface LanguagesProps {
  applicantDetails: ApplicantDetails;
}

export const Languages: React.FC<LanguagesProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  return (
    <Box width="100%">
      <Typography sx={{ mt: 2, mb: 0, mx: 1 }} variant="h5" fontWeight="bold">
        {t('applicantDetails.language')}
      </Typography>
      <Grid container direction={"row"} sx={{ m: 2 }}>
        {applicantDetails.languages.map((language, i) => {
          let divider = i < applicantDetails.languages.length - 1 ? ", " : "";
          return (
            <Typography key={i} sx={{ mr: 1 }} variant="body1">
              {language.name}
              {divider}
            </Typography>
          );
        })}
      </Grid>
    </Box>
  );
};