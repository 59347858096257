export type Meeting = {
  id: string;
  startTime: string;
  endTime: string;
  applicantId: string;
  applicantFullName: string;
  applicantIconUrl: string;
  applicantIntroduction: string;
  type: MeetingType;
};

export enum MeetingType {
  Physical = "Physical",
  Virtual = "Virtual",
}
