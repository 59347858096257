import React, { ReactNode } from "react";
import { Box, Backdrop, Modal, Fade } from "@mui/material";

interface MaijaModalProps {
  isOpen: boolean;
  handleClose: () => void;
  width?: string;
  maxWidth?: string;
  maxHeight?: string;
  children: ReactNode;
}

// TODO: make scrollable
const MaijaModal: React.FC<MaijaModalProps> = ({ children, isOpen, width, maxWidth, maxHeight, handleClose }) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ?? "80%",
    maxWidth: maxWidth ?? "auto",
    maxHeight: maxHeight ?? "auto",
    bgcolor: "#fafafa",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    p: 5,
    overflowY: "auto",
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
};

export default MaijaModal;
