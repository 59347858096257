import { useEffect, useState } from "react";
import { fetchOfficeItems } from "./OfficeListRepository";
import { Box, Card, List, Divider, ListItem, Grid, Paper, ListItemButton, Typography, Button, Fab } from "@mui/material";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { navigateToOfficeDetails } from "../../Routes";
import { COMPANY_CREATE_ROUTE } from "../../Routes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setOffices } from "../../reducers/officeListPageSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import ClickableHeaderItem from "../../components/ClickableHeaderItem";
import MaijaContainer from "../../components/MaijaContainer";
import { setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { useTranslation } from "react-i18next";

const OfficesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const offices = useAppSelector((state) => state.officeListPageSlice.offices);

  useEffect(() => {
    setIsLoading(true);
    fetchOfficeItems()
      .then((data) => dispatch(setOffices(data)))
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading && offices.length === 0) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (offices.length === 0) {
    return (
      <MaijaContainer>
        <OfficesListEmptyState />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => {
          dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.OfficeTab));
          navigate(COMPANY_CREATE_ROUTE.path);
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("officesList.addOffice")}</Typography>
      </Fab>
      <Typography variant="h4" gutterBottom sx={{ ml: 5 }}>
        {t("officesList.yourOffices")}
      </Typography>
      <List component={Paper} sx={{ m: 5, mb: 30 }}>
        <ListItem key={"header"}>
          <Grid container>
            <Grid item xs={3}>
              <ClickableHeaderItem
                text={t("officesList.location")}
                onClick={() => {
                  const sortedOffices = offices.slice().sort((a, b) => a.name.localeCompare(b.name));
                  dispatch(setOffices(sortedOffices));
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <ClickableHeaderItem
                text={t("officesList.numberOfJobCoaches")}
                onClick={() => {
                  const sortedOffices = offices.slice().sort((a, b) => a.numberJobCoaches - b.numberJobCoaches);
                  dispatch(setOffices(sortedOffices));
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <ClickableHeaderItem
                text={t("officesList.numberOfApplicants")}
                onClick={() => {
                  const sortedOffices = offices.slice().sort((a, b) => a.numberApplicants - b.numberApplicants);
                  dispatch(setOffices(sortedOffices));
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <ClickableHeaderItem
                text={t("officesList.appliedJobsLast30Days")}
                onClick={() => {
                  const sortedOffices = offices.slice().sort((a, b) => a.numberJobsLast30Days - b.numberJobsLast30Days);
                  dispatch(setOffices(sortedOffices));
                }}
              />
            </Grid>
          </Grid>
        </ListItem>

        <Divider />

        {offices.map((office) => {
          return (
            <ListItemButton
              key={office.id}
              onClick={() => {
                navigateToOfficeDetails(navigate, office.id);
              }}
            >
              <Grid container>
                <Grid item xs={3}>
                  {office.name}
                </Grid>
                <Grid item xs={3}>
                  {office.numberJobCoaches}
                </Grid>
                <Grid item xs={3}>
                  {office.numberApplicants}
                </Grid>
                <Grid item xs={3}>
                  {office.numberJobsLast30Days}
                </Grid>
              </Grid>
            </ListItemButton>
          );
        })}
      </List>
    </MaijaContainer>
  );
};

const OfficesListEmptyState = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateOfficeButton = () => {
    dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.OfficeTab));
    navigate(COMPANY_CREATE_ROUTE.path);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <DomainAddIcon style={{ fontSize: 150 }} sx={{ mb: 5 }} />
          <Typography variant="h4" gutterBottom>
            {t("officesList.noOffices")}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCreateOfficeButton} sx={{ m: 5 }}>
            {t("officesList.createOffice")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default OfficesListPage;
