import React from "react";
import { useEffect, useState } from "react";
import { Box, Card, Container, List, ListItem, Grid, Paper, ListItemButton, Typography, Fab, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { COMPANY_CREATE_ROUTE, navigateToApplicantDetails } from "../../Routes";
import { CREATE_APPLICANT_ROUTE } from "../../Routes";
import { ApplicantItem } from "../../types/ApplicantItem";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setApplicants, setDisplayedColumns } from "../../reducers/applicantListPageSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import ClickableHeaderItem from "../../components/ClickableHeaderItem";
import MaijaContainer from "../../components/MaijaContainer";
import { formatDateToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { SecurityLevel } from "../../types/User";
import { setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { SentimentDissatisfied } from "@mui/icons-material";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import getApplicantAnalyticsItems from "./AnalyticsRepository";
import ColumnSelectionButton from "../applicant-list/ColumnSelectionButton";
import { setAnalyticsApplicants } from "../../reducers/analyticsPageSlice";

export const AnalyticsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const applicants = useAppSelector((state) => state.applicantListPageSlice.applicants);
  const loggedInUser = useAppSelector((state) => state.user.user);
  const displayedColumns = useAppSelector((state) => state.applicantListPageSlice.displayedColumns);

  useEffect(() => {
    setIsLoading(true);
    getApplicantAnalyticsItems()
      .then((data) => dispatch(setAnalyticsApplicants(data)))
      .finally(() => setIsLoading(false));
  }, [loggedInUser?.jobCoachId, dispatch]);

  const handleColumnChange = (value: string) => {
    const newDisplayedColumns = displayedColumns.includes(value)
      ? displayedColumns.filter((col) => col !== value)
      : [...displayedColumns, value];
    dispatch(setDisplayedColumns(newDisplayedColumns));
  };

  if (isLoading && applicants.length === 0) {
    return (
      <Container style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </Container>
    );
  }

  return (
    <MaijaContainer>
      <Typography variant="h4" sx={{ mb: 4 }}>
        {t("analyticsPage.title")}
      </Typography>
    </MaijaContainer>
  );
};

type ApplicantListProps = {
  applicants: ApplicantItem[];
  displayedColumns: string[];
  updateApplicantsList: (updatedList: ApplicantItem[]) => void;
};

export const ApplicantList: React.FC<ApplicantListProps> = ({ applicants, displayedColumns, updateApplicantsList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const applicantsWithJob = applicants.filter((applicant) => applicant.hasJob);
  const applicantsWithoutJob = applicants.filter((applicant) => !applicant.hasJob);

  const renderApplicantItem = (applicant: ApplicantItem) => (
    <ListItemButton
      key={applicant.id}
      onClick={() => {
        navigateToApplicantDetails(navigate, applicant.id);
      }}
    >
      <Box display="flex">
        {displayedColumns.includes("fullName") && (
          <Box flex={1} minWidth={200}>
            {applicant.fullName}
          </Box>
        )}
        {displayedColumns.includes("evaluationCriteria") && (
          <Box flex={1} minWidth={200}>
            {applicant.evaluationCriteria}
          </Box>
        )}
        {displayedColumns.includes("appliedJobsLast30Days") && (
          <Box flex={1} minWidth={200}>
            {applicant.appliedJobsLast30Days}
          </Box>
        )}
        {displayedColumns.includes("nonAppliedJobsCount") && (
          <Box flex={1} minWidth={200}>
            {applicant.nonAppliedJobsCount}
          </Box>
        )}
        {displayedColumns.includes("latestLogin") && (
          <Box flex={1} minWidth={200}>
            {formatDateToString(applicant.latestLogin)}
          </Box>
        )}
        {displayedColumns.includes("profileCompletion") && (
          <Box flex={1} minWidth={200}>
            {`${Number(applicant.profileCompletion) * 100}%`}
          </Box>
        )}
        {displayedColumns.includes("educationLevel") && (
          <Box flex={1} minWidth={200}>
            {t(`educationLevelsEnum.${applicant.educationLevel}`)}
          </Box>
        )}
        {displayedColumns.includes("sfiLevel") && (
          <Box flex={1} minWidth={200}>
            {t(`sfiLevelsEnum.${applicant.sfiLevel}`)}
          </Box>
        )}
        {displayedColumns.includes("canTakeWorkImmediately") && (
          <Box flex={1} minWidth={200}>
            {applicant.canTakeWorkImmediately ? t("applicantsList.yes") : t("applicantsList.no")}
          </Box>
        )}
        {displayedColumns.includes("jobCoachName") && (
          <Box flex={1} minWidth={200}>
            {applicant.jobCoachName}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );

  const renderHeader = () => (
    <ListItem key={"header"}>
      <Box display="flex">
        {displayedColumns.includes("fullName") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.name")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("evaluationCriteria") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.evaluationCriteria")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => a.evaluationCriteria.localeCompare(b.evaluationCriteria));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("appliedJobsLast30Days") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.appliedJobs")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => a.appliedJobsLast30Days - b.appliedJobsLast30Days);
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("nonAppliedJobsCount") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.nonAppliedJobs")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => a.nonAppliedJobsCount - b.nonAppliedJobsCount);
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("latestLogin") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.latestLogin")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => {
                  if (!a.latestLogin && b.latestLogin) {
                    return 1;
                  } else if (a.latestLogin && !b.latestLogin) {
                    return -1;
                  } else if (!a.latestLogin && !b.latestLogin) {
                    return 0;
                  }
                  return a.latestLogin.localeCompare(b.latestLogin);
                });
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("profileCompletion") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.profileCompletion")}
              onClick={() => {
                const sortedApplicants = applicants
                  .slice()
                  .sort((a, b) => (Number(a.profileCompletion) || 0) - (Number(b.profileCompletion) || 0));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("educationLevel") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.educationLevel")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => (a.educationLevel ?? "").localeCompare(b.educationLevel ?? ""));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("sfiLevel") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.sfiLevel")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => (a.sfiLevel ?? "").localeCompare(b.sfiLevel ?? ""));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("canTakeWorkImmediately") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.canTakeWorkImmediately")}
              onClick={() => {
                const sortedApplicants = applicants
                  .slice()
                  .sort((a, b) => (a.canTakeWorkImmediately === b.canTakeWorkImmediately ? 0 : a.canTakeWorkImmediately ? -1 : 1));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
        {displayedColumns.includes("jobCoachName") && (
          <Box flex={1} minWidth={200}>
            <ClickableHeaderItem
              text={t("applicantsList.jobCoachName")}
              onClick={() => {
                const sortedApplicants = applicants.slice().sort((a, b) => a.jobCoachName.localeCompare(b.jobCoachName));
                updateApplicantsList(sortedApplicants);
              }}
            />
          </Box>
        )}
      </Box>
    </ListItem>
  );

  return (
    <Box>
      <Paper sx={{ mb: 4, p: 2 }}>
        {applicantsWithoutJob.length > 0 ? (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
              <Typography variant="h6">{t("applicantsList.applicantsWithoutJob")}</Typography>
              <ColumnSelectionButton />
            </Box>

            <List sx={{ overflowX: 'auto' }}>
              {renderHeader()}
              {applicantsWithoutJob.map(renderApplicantItem)}
            </List>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
              <Typography variant="h6">{t("applicantsList.applicantsWithoutJob")}</Typography>
            </Box>
          
            <MaijaEmptyState message={t("applicantsList.noApplicantsWithoutJob")}/>
          </>
        )}
      </Paper>

      <Paper sx={{ mb: 4, p: 2 }}>
        {applicantsWithJob.length > 0 ? (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
              <Typography variant="h6">{t("applicantsList.applicantsWithJob")}</Typography>
              <ColumnSelectionButton />
            </Box>

            <List sx={{ overflowX: 'auto' }}>
              {renderHeader()}
              {applicantsWithJob.map(renderApplicantItem)}
            </List>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
              <Typography variant="h6">{t("applicantsList.applicantsWithJob")}</Typography>
            </Box>
          
            <MaijaEmptyState message={t("applicantsList.noApplicantsWithJob")}/>
          </>
        )}
      </Paper>
    </Box>
  );
};

export const ApplicantsListEmptyState = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loggedInUser = useAppSelector((state) => state.user.user);

  const handleCreateApplicantButton = () => {
    if (loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin) {
      dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.ApplicantTab));
      navigate(COMPANY_CREATE_ROUTE.path);
    } else {
      navigate(CREATE_APPLICANT_ROUTE.path);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SentimentDissatisfied style={{ fontSize: 200 }} />
          <Typography variant="h4" gutterBottom>
            {t("applicantsList.noApplicants")}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCreateApplicantButton} sx={{ marginTop: 5 }}>
            {t("applicantsList.createApplicant")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default AnalyticsPage;
