import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApplicantItem } from "../types/ApplicantItem";

interface ApplicantListPageState {
  applicants: ApplicantItem[];
  displayedColumns: string[];
}

const initialState: ApplicantListPageState = {
  applicants: [],
  displayedColumns: ["fullName", "evaluationCriteria", "appliedJobsLast30Days", "nonAppliedJobsCount", "latestLogin"],
};

export const applicantListPageSlice = createSlice({
  name: "applicantList",
  initialState,
  reducers: {
    setApplicants: (state, action: PayloadAction<ApplicantItem[]>) => {
      state.applicants = action.payload;
    },
    setDisplayedColumns: (state, action: PayloadAction<string[]>) => {
      state.displayedColumns = action.payload;
    },
    clearApplicantListPage: (state) => {
      state.applicants = [];
      state.displayedColumns = ["fullName", "evaluationCriteria", "appliedJobsLast30Days", "nonAppliedJobsCount", "latestLogin"];
    },
  },
});

export const { setApplicants, setDisplayedColumns, clearApplicantListPage } = applicantListPageSlice.actions;
export const applicantListPage = (state: RootState) => state.applicantListPageSlice;
export default applicantListPageSlice.reducer;
