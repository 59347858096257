import { CompanySettings } from "../../types/CompanySettings";
import axiosInstance from "../../utils/AxiosInstance";

export async function updateSettings(data: CompanySettings): Promise<void> {
  await axiosInstance.post<void>("/company/settings", data).then((response) => {
    console.log(response);
  });
}

export async function getSettings(companyId: string): Promise<CompanySettings | null> {
  try {
    const response = await axiosInstance.get(`/company_settings_login?company_id=${companyId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
