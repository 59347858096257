import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApplicantAnalyticsItem } from "../types/ApplicantAnalyticsItem";

interface AnalyticsPageState {
  applicants: ApplicantAnalyticsItem[];
}

const initialState: AnalyticsPageState = {
  applicants: [],
};

export const applicantListPageSlice = createSlice({
  name: "applicantList",
  initialState,
  reducers: {
    setAnalyticsApplicants: (state, action: PayloadAction<ApplicantAnalyticsItem[]>) => {
      state.applicants = action.payload;
    },
    clearAnalyticsPage: (state) => {
      state.applicants = [];
    },
  },
});

export const { setAnalyticsApplicants, clearAnalyticsPage } = applicantListPageSlice.actions;
export const applicantListPage = (state: RootState) => state.applicantListPageSlice;
export default applicantListPageSlice.reducer;
