import { useState, useEffect, useRef, ReactNode } from "react";
import Box from "@mui/material/Box";
import MaijaLoadingPage from "./MaijaLoadingPage";
import MaijaErrorPage from "./MaijaErrorPage";

interface AsyncComponentProps<T> {
  fetchData: () => Promise<T>;
  LoadingComponent?: ReactNode;
  ErrorComponent?: ReactNode;
  minHeight?: string | number;
  children: (data: T) => ReactNode;
}

const MaijaAsyncComponent = <T,>({
  fetchData,
  LoadingComponent = <MaijaLoadingPage />,
  ErrorComponent = <MaijaErrorPage />,
  minHeight = "auto",
  children,
}: AsyncComponentProps<T>) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      const fetchAsyncData = async () => {
        setLoading(true);
        try {
          const result = await fetchData();
          setData(result);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      };
      fetchAsyncData();
      hasFetchedData.current = true;
    }
  }, [fetchData]);

  if (data) {
    return <Box minHeight={minHeight}>{children(data as T)}</Box>;
  }

  return (
    <Box minHeight={minHeight} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      {loading && LoadingComponent}
      {error && ErrorComponent}
    </Box>
  );
};

export default MaijaAsyncComponent;
