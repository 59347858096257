import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

interface ClickableHeaderItemProps {
    text: string;
    onClick: () => void;
}

const ClickableHeaderItem: React.FC<ClickableHeaderItemProps> = ({ text, onClick }) => {
    return (
        <ButtonBase
            sx={{
                py: 2,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start'
            }}
            style={{ width: '100%' }}
            onClick={onClick}
        >
            <Typography style={{ userSelect: 'none', alignSelf: 'flex-start' }}>
                {text}
            </Typography>
        </ButtonBase>
    );
};

export default ClickableHeaderItem;
