import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import type { User } from "../types/User";

interface UserState {
  user?: User;
}

const initialState: UserState = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setProfilePictureUrl: (state, action: PayloadAction<string | null>) => {
      if (!state.user) {
        return;
      }
      console.log("setProfilePictureUrl", action.payload);
      state.user.profileIconUrl = action.payload;
    },
    clearLoggedInUser: (state) => {
      state.user = undefined;
    },
  },
});

export const { setLoggedInUser, setProfilePictureUrl, clearLoggedInUser } = userSlice.actions;
export const user = (state: RootState) => state.user.user;
export default userSlice.reducer;
