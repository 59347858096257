import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getMaijaRoutes, APPLICANT_LIST_ROUTE, LOGIN_ROUTE } from "./Routes";
import ApplicantsListPage from "./pages/applicant-list/ApplicantsListPage";
import LoginPage from "./pages/login/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import MaijaSnackbar from "./components/MaijaSnackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SecurityLevel } from "./types/User";
import "./i18n";
import CustomThemeProvider from "./CustomThemeProvider";

function App() {
  const maijaRoutes = getMaijaRoutes();
  return (
    <CustomThemeProvider>
      <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path={LOGIN_ROUTE.path} index element={<LoginPage />} />
              {maijaRoutes.map((route) => {
                if (route.securityLevel === SecurityLevel.None) {
                  return <Route key={route.path} path={route.path} element={<route.component />} />;
                }
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<ProtectedRoute route={route}>{React.createElement(route.component)}</ProtectedRoute>}
                  />
                );
              })}
              <Route
                path="*"
                element={
                  <ProtectedRoute route={APPLICANT_LIST_ROUTE}>
                    <ApplicantsListPage />
                  </ProtectedRoute>
                }
              ></Route>
            </Routes>
            <MaijaSnackbar />
          </Router>
        </LocalizationProvider>
      </React.Fragment>
    </CustomThemeProvider>
  );
}

export default App;
