import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApplicantDetails } from "../types/ApplicantDetails";

interface ApplicantDetailsPageState {
  applicantsMap: { [id: string]: ApplicantDetails };
}

const initialState: ApplicantDetailsPageState = {
  applicantsMap: {},
};

export const applicantDetailsPageSlice = createSlice({
  name: "applicantDetails",
  initialState,
  reducers: {
    setApplicant: (state, action: PayloadAction<ApplicantDetails>) => {
      state.applicantsMap[action.payload.applicantId] = action.payload;
    },
    clearApplicantDetailsPage: (state) => {
      state.applicantsMap = {};
    },
  },
});

export const { setApplicant, clearApplicantDetailsPage } = applicantDetailsPageSlice.actions;
export const applicantDetailsPage = (state: RootState) => state.applicantDetilsPageSlice;
export default applicantDetailsPageSlice.reducer;
